import React from 'react';
import { ContactFormComp, Layout, PageHeader, Seo } from '../components';
import { graphql } from 'gatsby';
import styled from 'styled-components';

const ShippersQuote = ({ data }) => {
   return (
      <Layout>
         <Seo title='Get a Shippers Quote' />
         <PageHeader
            title='Get a Shippers Quote'
            bgImg={data.titleImage.childImageSharp.gatsbyImageData}
         />

         <ShippersForm className='container'>
            <ContactFormComp formName='shippers' />
         </ShippersForm>
      </Layout>
   );
};

export const query = graphql`
   {
      titleImage: file(name: { eq: "contact-header" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
   }
`;

export default ShippersQuote;

const ShippersForm = styled.section`
   max-width: 800px !important;
   margin: auto;
`;
